.sidebar .text {
  font-size: 16px;
  font-weight: 700;
  /* color: var(--light); */
  color: black !important;
  transition: var(--trans-03);
  white-space: nowrap;
  opacity: 1;
}
.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: white;
  transition: var(--trans-05);
  z-index: 100;
  /* border-right: 10px var(--blue); */
}
.sidebar.close {
  width: 90px;
}
.sidebar.close .text {
  opacity: 0;
}

/* .sidebar .image-text img {
	width: 150px;
	border-radius: 6px;
} */
.sidebar header .image-text {
  transition: var(--trans-05);
}

header .image-text .header-text {
  display: flex;
  flex-direction: column;
  text-align: right;
}
header.close .image-text .header-text {
  display: none;
}

.header-text .name {
  font-weight: 600;
}
.header-text .profession {
  margin-top: -2px;
}
.sidebar header {
  position: relative;
  padding-top: 20px;
}
.sidebar li {
  height: 50px;
  list-style: none;
  margin-top: 30px;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar.close li {
  overflow: hidden;
}
.sidebar.close li.button {
  overflow: visible;
}

.sidebar.close li.button .dropdown-menu * {
  background: transparent !important;
}

.sidebar li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  min-width: 60px;
}
.sidebar li .icon,
.sidebar li .text {
  color: var(--white);
  transition: var(--trans-02);
}

.sidebar header .toggle {
  position: absolute;
  top: 25%;
  right: -10px;
  transform: translateY(-50%) rotate(180deg);
  height: 35px;
  width: 35px;
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--bland);
  font-size: 24px;
  transition: var(--trans-03);
}
.sidebar.close header .toggle {
  top: 100%;
}
.sidebar li .menuBtn,
.sidebar li a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 6px;
  transition: var(--trans-04);
}
.sidebar li.headerActive,
.sidebar li .menuBtn:hover,
.sidebar li a:hover {
  border-radius: 6px;
  background: var(--blue);
}
.sidebar li.headerActive .icon,
.sidebar li.headerActive .text,
.sidebar li .menuBtn:hover .icon,
.sidebar li .menuBtn:hover .text,
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--white);
}

.sidebar li.headerActive:hover a,
.sidebar li.headerActive:hover {
  background: var(--light-blue);
}

.sidebar .menu-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 100px);
  overflow-y: auto;
  padding: 10px 14px 20px;
}

.sidebar.close .menu-bar {
  height: calc(100% - 50px);
}
.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 44px;
  background: var(--blue);
  border-radius: 25px;
  transition: var(--tran-03);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  background: var(--dark-blue);
  top: 50%;
  border-radius: 50%;
  left: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.sidebar.close header .toggle {
  transform: translateY(-50%);
}

/* .sidebar.close .image-text img {
	width: 70px;
	border-radius: 6px;
} */

.home {
  height: 100vh;
  position: relative;
  background-color: "white";
  left: 250px;
  width: calc(100% - 250px);
  transition: var(--trans-05);
  overflow-y: auto;
}

.sidebar.close ~ .home {
  left: 90px;
  width: calc(100% - 90px);
}
.sidebar.close .image .logo {
  width: 50px;
  height: 50px;
}

.sidebar .image .logo {
  width: auto;
  height: 100px;
}

@media screen and (max-width: 768px) {
  .sidebar {
    padding: 10px 0px 5px;
    z-index: 1100 !important;
  }
  .fontReduce {
    font-size: 12px;
  }
  .sidebar.close .menu-bar,
  .sidebar .menu-bar {
    padding: 50px 7px 100px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
  .sidebar.close {
    width: 70px;
    left: -70px;
  }
  .sidebar.close ~ .home {
    left: 0px;
    width: calc(100%);
  }
  .sidebar ~ .home {
    left: 0px;
    width: calc(100%);
  }
  .sidebar header .toggle {
    transform: rotate(90deg);
  }
  .sidebar header .toggle {
    height: 20px;
    width: 20px;
  }
  .sidebar header .toggle {
    top: 8%;
    right: -28px;
  }
  .sidebar.close header .toggle {
    top: 42%;
    right: -28px;
  }
  .sidebar header .toggle.toggleBar {
    background: transparent;
    border-radius: 0%;
    color: var(--blue);
  }
  .sidebar .text {
    font-size: 14px;
  }
}
